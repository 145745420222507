import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import * as BlockContent from "@sanity/block-content-to-react"
import theme from "../shared/theme"
import styled from "@emotion/styled"
import { Fade } from "react-slideshow-image"
// import ImageRequest from "../components/imageRequest/imageRequest"
import { Body, StyledLink } from "../shared/styledElements"
import BlockRenderer from "../utils/textRenderer.js"
import Layout from "../shared/layout.js"

export const query = graphql`
  query($slug: String) {
    sanityProjectProjekte(slug: { current: { eq: $slug } }) {
      project_headline
      project_category
      alt_text
      description
      preview_image {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawProjectText
      _rawPictureGallery(resolveReferences: { maxDepth: 25 })
      _rawProjectPreviewText
      picture_gallery {
        isForSale {
          imageForSale
          specialPaper
          standardPaper
          smallFormatImageSize
          smallFormat
          price_smallFormat
          price_bigFormat
          deliveryCost_small
          deliveryCost_big
          bigFormatImageSize
          bigFormat
        }
      }
    }
  }
`

const properties = {
  // duration: 5000,
  // transitionDuration: 1000,
  infinite: true,
  autoplay: false,
  arrows: true
}

const localDescription =
  "Kunstfotografie, Fotoprojekte, Langzeitbelichtungen und Dokumentationen. Lumenas Photography."

export default ({ data }) => (
  <Layout>
    <Helmet>
      <html lang="de" />
      <title>
        {data
          ? data.sanityProjectProjekte.project_headline
          : "Lumenas Photography Projekte"}
      </title>
      <meta
        name="description"
        content={
          data.sanityProjectProjekte.description
            ? data.sanityProjectProjekte.description
            : localDescription
        }
      ></meta>
    </Helmet>
    <Body>
      <div id="modal-root">
        <Headline>
          <FirstRow>
            <span>
              {data.sanityProjectProjekte.project_category
                ? data.sanityProjectProjekte.project_category
                : ""}
            </span>
            <StyledLink aria-label="zurück zur Startseite" to="/">
              ZUR STARTSEITE
            </StyledLink>
          </FirstRow>
          <h1>
            {data.sanityProjectProjekte.project_headline
              ? data.sanityProjectProjekte.project_headline
              : ""}
          </h1>
        </Headline>
        <ProjectText>
          <div>
            <BlockContent
              blocks={
                data.sanityProjectProjekte._rawProjectText
                  ? data.sanityProjectProjekte._rawProjectText
                  : ""
              }
              serializers={{ types: { block: BlockRenderer } }}
            />
          </div>
        </ProjectText>
        <Seperator />

        {/* <Content> */}
        <PictureContainerSlide>
          <Fade {...properties}>
            {data.sanityProjectProjekte._rawPictureGallery.map((item, index) =>
              item.image.asset.metadata.dimensions.aspectRatio >= 1 ? (
                <div key={index}>
                  <ImageInfoHead>
                    <ImageInfo>
                      {data.sanityProjectProjekte.project_headline
                        ? data.sanityProjectProjekte.project_headline
                        : ""}
                    </ImageInfo>
                    {/* temorarily not used - remove if not wanted in future */}
                    {/* <ImageRequestContainer>
                      {item.isForSale ? (
                        <ImageRequest
                          imageUrl={
                            item.image.asset.url && item.image.asset.url
                          }
                          smallSize={
                            item.isForSale.smallFormat &&
                            item.isForSale.smallFormat
                          }
                          bigSize={
                            item.isForSale.bigFormat && item.isForSale.bigFormat
                          }
                          smallSizePrice={
                            item.isForSale.price_smallFormat &&
                            item.isForSale.price_smallFormat
                          }
                          bigSizePrice={
                            item.isForSale.price_bigFormat &&
                            item.isForSale.price_bigFormat
                          }
                          standardPaper={
                            item.isForSale.standardPaper &&
                            item.isForSale.standardPaper
                          }
                          highArtPaper={
                            item.isForSale.specialPaper &&
                            item.isForSale.specialPaper
                          }
                          bigFormatImageSize={
                            item.isForSale.bigFormatImageSize &&
                            item.isForSale.bigFormatImageSize
                          }
                          smallFormatImageSize={
                            item.isForSale.smallFormatImageSize &&
                            item.isForSale.smallFormatImageSize
                          }
                        />
                      ) : null}
                    </ImageRequestContainer> */}
                  </ImageInfoHead>

                  <Picture key={index} className="each-fade">
                    <div
                      className="querformat"
                      style={{
                        backgroundImage: `url(${item.image.asset.url})`
                      }}
                      title={item.image_title ? item.image_title : ""}
                      alt_text={item.alt_text ? item.alt_text : ""}
                    />
                  </Picture>
                  <ImageSubline>
                    <ImageTitle>{item.image_title}</ImageTitle>
                    <ImageInfo>
                      {index +
                        1 +
                        "/" +
                        data.sanityProjectProjekte._rawPictureGallery.length}
                    </ImageInfo>
                  </ImageSubline>
                </div>
              ) : item.image.asset.metadata.dimensions.aspectRatio <= 1.2 &&
                item.image.asset.metadata.dimensions.aspectRatio >= 0.8 ? (
                <div key={index}>
                  <ImageInfoHead>
                    <ImageInfo>
                      {data.sanityProjectProjekte.project_headline
                        ? data.sanityProjectProjekte.project_headline
                        : ""}
                    </ImageInfo>
                    {/* <ImageRequestContainer>
                      {item.isForSale ? (
                        <ImageRequest
                          imageUrl={
                            item.image.asset.url && item.image.asset.url
                          }
                          smallSize={
                            item.isForSale.smallFormat &&
                            item.isForSale.smallFormat
                          }
                          bigSize={
                            item.isForSale.bigFormat && item.isForSale.bigFormat
                          }
                          smallSizePrice={
                            item.isForSale.price_smallFormat &&
                            item.isForSale.price_smallFormat
                          }
                          bigSizePrice={
                            item.isForSale.price_bigFormat &&
                            item.isForSale.price_bigFormat
                          }
                          standardPaper={
                            item.isForSale.standardPaper &&
                            item.isForSale.standardPaper
                          }
                          highArtPaper={
                            item.isForSale.specialPaper &&
                            item.isForSale.specialPaper
                          }
                          bigFormatImageSize={
                            item.isForSale.bigFormatImageSize &&
                            item.isForSale.bigFormatImageSize
                          }
                          smallFormatImageSize={
                            item.isForSale.smallFormatImageSize &&
                            item.isForSale.smallFormatImageSize
                          }
                        />
                      ) : null}
                    </ImageRequestContainer> */}
                  </ImageInfoHead>
                  <Picture key={index} className="each-fade">
                    <div
                      className="quadrat"
                      style={{
                        backgroundImage: `url(${item.image.asset.url})`
                      }}
                    />
                  </Picture>
                  <ImageSubline>
                    <ImageTitle>{item.alt_text}</ImageTitle>
                    <ImageInfo>
                      {index +
                        1 +
                        "/" +
                        data.sanityProjectProjekte._rawPictureGallery.length}
                    </ImageInfo>
                  </ImageSubline>
                </div>
              ) : (
                <div key={index}>
                  <ImageInfo>
                    {data.sanityProjectProjekte.project_headline
                      ? data.sanityProjectProjekte.project_headline
                      : ""}
                  </ImageInfo>
                  <Picture className="each-fade">
                    <div
                      className="hochformat"
                      style={{
                        backgroundImage: `url(${item.image.asset.url})`
                      }}
                    />
                  </Picture>
                  <ImageSubline>
                    <ImageTitle>{item.alt_text}</ImageTitle>
                    <ImageInfo>
                      {index +
                        1 +
                        "/" +
                        data.sanityProjectProjekte._rawPictureGallery.length}
                    </ImageInfo>
                  </ImageSubline>
                </div>
              )
            )}
          </Fade>
        </PictureContainerSlide>

        <LinkContainer>
          <StyledLink aria-label="zurück zur Startseite" to="/">
            ZUR STARTSEITE
          </StyledLink>
        </LinkContainer>
      </div>
    </Body>
  </Layout>
)

const Headline = styled.div`
  font-family: ${theme.fontFamilyHeading};
  text-transform: uppercase;
  padding-bottom: ${theme.spaces.standardPadding};

  h1 {
    font-size: calc(2 * ${theme.fontsizes.h1MediumScreen});
  }

  span {
    font-size: ${theme.fontsizes.subBannerMediumScreen};
    font-weight: lighter;
  }

  @media all and (max-width: 768px) {
    h1 {
      font-size: calc(3 * ${theme.fontsizes.h1MediumScreen});
    }

    span {
      font-size: calc(2 * ${theme.fontsizes.subBannerMediumScreen});
    }
  }
`

const ProjectText = styled.div`
  width: calc(100% / 3 * 2);
  margin-left: calc(100% / 3 * 1);
  border-left: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};

  div {
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontsizes.textMediumScreen};
    line-height: ${theme.spaces.textMediumScreenLineHeight};
    text-align: justify;
    padding-left: 1vw;
  }

  @media all and (max-width: 768px) {
    width: calc(100% / 5 * 4);
    margin-left: calc(100% / 5 * 1);

    div {
      font-size: ${theme.fontsizes.textSmallScreen};
      line-height: ${theme.spaces.textSmallScreenLineHeight};
    }
  }
`

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Seperator = styled.div`
  height: 0.25vw;
  width: 100%;
  border-bottom: ${theme.lineStrength.seperators} solid ${theme.colors.darkFont};
  margin-bottom: ${theme.spaces.standardPadding};
  margin-top: ${theme.spaces.standardPadding};
`

//Container für Bildaufnahme
const PictureContainerSlide = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 50%;

  @media all and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`

//Def. der Dimensionen für Anzeige hoch- und querformat
const Picture = styled.div`
  height: 30vw;

  .querformat {
    height: 100%;
    width: 100%;
    background-size: cover;
  }

  .hochformat {
    height: 30vw;
    width: 50%;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    background-color: ${theme.colors.bodyBackground};
  }

  .quadrat {
    height: 100%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
  }

  @media all and (max-width: 768px) {
    height: 60vw;

    .hochformat {
      height: 60vw;
    }

    span {
      font-size: ${theme.fontsizes.subBannerSmallScreen};
    }
  }
`

const ImageSubline = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ImageTitle = styled.div`
  font-family: ${theme.fontFamily};
  font-size: ${theme.fontsizes.textMediumScreen};
  line-height: calc(1.5 * ${theme.spaces.textMediumScreenLineHeight});
`

const ImageInfo = styled.div`
  font-family: ${theme.fontFamilyHeading};
  font-size: ${theme.fontsizes.h1MediumScreen};
  font-weight: bold;
  text-transform: uppercase;
`

const ImageInfoHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

// const ImageRequestContainer = styled.div`
//   margin: auto 0;
// `

const LinkContainer = styled.div`
  margin-top: ${theme.spaces.standardBlockTopEnd};
`
